// theme/index.js
import { extendTheme } from '@chakra-ui/react'

// Global style overrides
import { styles } from './styles'

export const myTheme = extendTheme({
    styles,
    colors: {
      "cyan": {
        "50": "#E7F1FD",
        "100": "#e18033",
        "200": "#92C0F7",
        "300": "#67A7F4",
        "400": "#3C8EF1",
        "500": "#1175EE",
        "600": "#0E5EBE",
        "700": "#0A468F",
        "800": "#072F5F",
        "900": "#031730"
      },
      brand: {
        100: "#f7fafc",
        // ...
        900: "#1a202c",
      },
      "abtg_orange": {
        "base": '#e18034',
        "lighter": '#ef8836',
        "darker": '#d1772f',
      },
      "abtg": {
        "white": '#ffffff',
        "gray": '#404040',
        // "darker": '#d1772f',
      }
    },
    config: {
      useSystemColorMode: false,
      initialColorMode: "dark"
    }
  })

// export default theme