import { shortenIfAddress } from "@usedapp/core";
import { Notification } from "./Notification";
import { getConf } from "../config";

export const shortAddress = (user) => {
    try {
        return shortenIfAddress(isUserValid(user) ? user.wallet : '')
    } catch (error) {
        return isUserValid(user) ? user.wallet : ''
    }
}

export const walletAddress = (user) => {
    // const wallet = localStorage.account;
    // const _wallet = user != undefined ? user.wallet : ''
    return isUserValid(user) ? user.wallet : ''
}

export const hasWallet = (user) => {
    // return true
    // console.log('hasWallet');
    try {
        const wallet = shortenIfAddress(isUserValid(user) ? user.wallet : '')
        // console.log('hasWallet wallet', wallet)
        return wallet !== '' ? true : false;
    } catch (error) {
        // console.log('hasWallet wallet', 'false')
        return false
    }
}

export const availWithdraw = (user) => {
    try {
        // return (parseFloat(user.point)*conf.skipoint_rate).toFixed(2)
        const avail = Math.round(((parseFloat(user.point)*getConf('skipoint_rate')) + Number.EPSILON) * 100) / 100;
        return avail > 0 ? avail : 0;
        // return (parseFloat(user.point)*conf.skipoint_rate)
    } catch (error) {
        // console.log('hasWallet wallet', 'false')
        return 0
    }
}

export const availPoint = (user) => {
    try {
        // return (parseFloat(user.point)*conf.skipoint_rate).toFixed(2)
        const avail = parseFloat(user.point);
        return avail > 0 ? avail : 0;
        // return (parseFloat(user.point)*conf.skipoint_rate)
    } catch (error) {
        // console.log('hasWallet wallet', 'false')
        return 0
    }
}

export const canWithdraw = (user, usd_ski_rate) => {
    try {
        // console.log('canWithdraw hasWallet',hasWallet(user) ? true : false )
        // console.log('canWithdraw availWithdraw',(availWithdraw(getUser(user))*usd_ski_rate) >= conf.min_withdraw ? true : false )
        return hasWallet(user) ? (availWithdraw(getUser(user))*usd_ski_rate) >= getConf('min_withdraw') ? true : false : false
        // return (availWithdraw(getUser(user))*usd_ski_rate) >= conf.min_withdraw ? true : false;
        // return Math.round(((parseFloat(user.point)*conf.skipoint_rate) + Number.EPSILON) * 100) / 100
    } catch (error) {
        return false
    }
}

export const checkWallet = (wallet) => {
    try {
        if (wallet === '') return true;
        const _wallet = shortenIfAddress(wallet)
        return _wallet !== '' ? true : false;
    } catch (error) {
        // console.log('hasWallet wallet', 'false')
        return false
    }
}

export const isUserValid = (user) => {
    // console.log('isUserValid '+user, (user === undefined || user === 'undefined') ? false : true)
    return (user === undefined || user === 'undefined') ? false : true;
}

export const getUser = (user) => {
    return isUserValid(user) ? user : undefined;
}

export const userSuccess = (user) => {
    try {
        // console.log('userSuccess ' + user, isUserValid(user) ? user.success : false)
        return isUserValid(user) ? user.success : false;
    } catch (error) {
        return false
    }
}




// export const getWeb3Accout = () => {
//     const { activateBrowserWallet, account, chainId } = useEthers();
//     console.log('getWeb3Accout account', account);
//     return account;
// }