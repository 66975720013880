import { mode } from '@chakra-ui/theme-tools'

export const styles = {
      global: (props) => ({
        body: {
          fontFamily: 'body',
          color: mode('gray.800', 'whiteAlpha.900')(props),
          bg: mode('abtg.white', 'abtg.white')(props),
          bgImage: "ABTGbackground.png",
          bgSize: 'cover',
          // w: '100%',
          // h: '200%',
          // minH: '100%',
          bgPosition: "center",
          bgRepeat: "no-repeat",
          lineHeight: 'base',
          backgroundAttachment: 'fixed'
        },
        // abtg_orange: {
        //   color: '#e18033',
        //   text_color: '#e18033',
        //   border: '5px',
        // }
      }),
}

// export default styles;