
import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useColorModeValue,
  Stack,
  Text,
  Center,
  Divider
} from '@chakra-ui/react';
// import { GetAccount } from "./ConnectButton";
// import { formatEther } from "@ethersproject/units";
import { ReactNode } from 'react';
// import { FaDeezer, FaGem, FaFunnelDollar, FaHandHoldingUsd, FaMoneyCheckAlt, FaPiggyBank, FaTicketAlt, FaWallet } from "react-icons/fa";
import { FaPiggyBank, FaWallet } from "react-icons/fa";
import { getConf } from '../config'
import { userSuccess, availPoint, availWithdraw } from "../utils/WalletUtils";

interface StatsCardProps {
  title: string;
  stat: string;
  // usd_ski_rate: number;
  icon: ReactNode;
  showUsd: boolean;
  state: any;
  showPoint: boolean;
  user: object;
  alreadyWithdrawn: string;
}
function StatsCard(props: StatsCardProps) {
  const { title, stat, icon, showUsd, state, showPoint, user, alreadyWithdrawn } = props;
  const availWtdrw = availWithdraw(state.user)
  return (
    <Stat
      px={{ base: 4, md: 8 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={useColorModeValue('abtg.white', 'abtg.white')}
      rounded={'lg'}
      background={'gray.900'}
      // bgGradient={'linear(to-r, gray.900, gray.800)'}
      // _hover={{
      //   // bgGradient: 'linear(to-r, red.500, yellow.500)',
      //   bgGradient: 'linear(to-r, gray.800, gray.700)',
      // }}
      >

      {/* <Stack direction={['column', 'row']} mb={[0, "-15px !important"]}>
        <Box pl={{ base: 2, md: 4 }}>
          {icon}
          <StatLabel fontSize={'2xl'} fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={'medium'} fontWeight={'medium'}>
            {stat} punti = {(parseFloat(stat) * getConf('skipoint_rate')).toFixed(2)} {getConf('default_ens')} {showUsd ? '= ~' + (parseFloat(stat) * getConf('skipoint_rate') * state.usd_ski_rate).toFixed(2) + ' ' + getConf('default_stable') : ''}
          </StatNumber>
        </Box>
        <Box
          // my={'auto'}
          mt="25px"
          color={useColorModeValue('gray.800', 'gray.200')}
          alignContent={'center'}>
            {stat} punti = {(parseFloat(stat) * getConf('skipoint_rate')).toFixed(2)} {getConf('default_ens')} {showUsd ? '= ~' + (parseFloat(stat) * getConf('skipoint_rate') * state.usd_ski_rate).toFixed(2) + ' ' + getConf('default_stable') : ''}
        </Box>
      </Stack> */}
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontSize={{ base: '1.2em', md: '1.8em' }} fontWeight={'medium'} isTruncated>
            {title + ': ' + stat }
          </StatLabel>
          {/* <StatNumber fontSize={{ base: 'medium', md: 'medium' }} fontWeight={'medium'}>
            {showPoint ? stat + ' punti = ' : ''}{(parseFloat(stat) * getConf('skipoint_rate')).toFixed(2)} {getConf('default_ens')} {showUsd ? '= ~' + (parseFloat(stat) * getConf('skipoint_rate') * state.usd_ski_rate).toFixed(2) + ' ' + getConf('default_stable') : ''}
          </StatNumber> */}
          <StatNumber fontSize={{ base: '12px', md: '16px' }} fontWeight={'medium'}>
            {alreadyWithdrawn ?
              <>
                {'Già convertiti: ' + (parseFloat(stat) - availPoint(user))}
                {availWtdrw > 0 && ' - Da convertire: ' + availPoint(user)}
              </>
              :
              <>
                {availWtdrw > 0 && 'Da convertire: ' + availPoint(user)}
              </>
            }
          </StatNumber>
          {/* { alreadyWithdrawn ?  */}
            {/* <StatHelpText fontSize={'xs'} fontWeight={'medium'}> */}
              {/* {'Already withdrawn: ' + alreadyWithdrawn + ' ' + getConf('default_ens')} */}
              {/* {'Già convertiti: ' + alreadyWithdrawn + ' ' + getConf('default_ens')}
              {availWtdrw > 0 && ' - Convertibili: ' + availWtdrw.toFixed(2) + ' ' + getConf('default_ens')} */}
              {/* {'Già convertiti: ' + (parseFloat(stat)-availPoint(user)) + ' Punti'} */}
              {/* {availWtdrw > 0 && ' - Da convertire: ' + availPoint(user) + ' Punti'} */}
            {/* </StatHelpText> */}
            {/* : */}
          {/* <StatHelpText fontSize={'xs'} fontWeight={'medium'}> */}
            {/* {availWtdrw > 0 && 'Da convertire: ' + availWtdrw.toFixed(2) + ' ' + getConf('default_ens')} */}
            {/* {availWtdrw > 0 && 'Da convertire: ' + availPoint(user) + ' Punti'} */}
          {/* </StatHelpText> */}
          {/* } */}
        </Box>
        <Box
          my={'auto'}
          color={useColorModeValue('gray.800', 'gray.200')}
          alignContent={'center'}>
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}

interface CardProps {
  usd_ski_rate: number;
  user: any;
  state: any;
}

export default function DetailsCards(props: CardProps) {
  // const { account } = GetAccount();
  const { usd_ski_rate, user, state } = props;
  // console.log('DetailsCards', state.conf.default_network_name);
  // state.user.withdrawls.map((w: any) => {
  //   // return <StatsCard key={index} date={item.checkDate} amount={item.gamepoint.toFixed(2).toString()} ip={item.requestip} transactionHash={item.txid} success={item.success} />
  //   console.log(w.checkDate)
  // });
  // return state.success ? (
  return userSuccess(state.user) ? (
    <>
    <Box maxW="3xl" mx={'auto'} pt={0} px={{ base: 2, sm: 12, md: 17 }}>
      {/* <chakra.h1
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}>
        Personal area of { state.user.name } { state.user.surname }
      </chakra.h1> */}
        <Center mb="5px">
          <Flex textAlign={'center'}>
            <Text as="span"
              fontSize={'3xl'}
              fontWeight='bold'
              color="abtg.white"
            >Area Personale di<Text as="span"
              fontSize={'3xl'}
              fontWeight='bold'
              color="abtg_orange.lighter"
            >&nbsp;{state.user.name} {state.user.surname}</Text>
            </Text>
          </Flex>
        </Center>
        <Divider mt="0px" opacity={1} borderColor={'abtg.white'} />
        <Center mt="10px" mb='15px'>
          <Flex textAlign={'center'}>
            <Text as="span"
              fontSize={'2xl'}
              fontWeight='bold'
              color="abtg.white"
            >Converti in <Text as="span"
              fontSize={'2xl'}
              fontWeight='bold'
              color="abtg_orange.lighter"
              ><a href="https://skillchain.io/it/" target="_blank">Skillchain</a></Text> i punti gioco guadagnati 
            </Text>
          </Flex>
        </Center>
        {/* <Center mb="15px">
          <Flex textAlign={'center'}>
            <Text as="span"
              fontSize={'2xl'}
              fontWeight='bold'
              color="abtg.white"
            >direttamente sul tuo wallet ERC20
            </Text>
          </Flex>
        </Center> */}
      {/* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}> */}
        {/* <StatsCard title={'Wallet'} stat={account != null ? account : ''} icon={<FaWallet size={'3em'} />} /> */}
          {/* <StatsCard title={'Accumulated points'} stat={accumulatedPoint.toString()} /> */}
          {/* <StatsCard title={'Earned Game points'} stat={parseFloat(user.totalPoint).toFixed(0)} icon={<FaPiggyBank size={'2em'} />} state={state} showUsd={false} showPoint={true} user={user} alreadyWithdrawn={''} /> */}
        <StatsCard title={'Punti gioco guadagnati'} stat={parseFloat(user.totalPoint).toFixed(0)} icon={<FaPiggyBank size={'2em'} />} state={state} showUsd={false} showPoint={true} user={user} alreadyWithdrawn={(parseFloat(user.totalPoint) * getConf('skipoint_rate') - availPoint(user) * getConf('skipoint_rate')).toFixed(2)} />
          {/* <StatsCard title={'Withdraw availibility'} stat={availPoint(user).toFixed(0)} icon={<FaWallet size={'2em'} />} state={state} showUsd={true} showPoint={false} user={user} alreadyWithdrawn={(parseFloat(user.totalPoint) * getConf('skipoint_rate') - availPoint(user) * getConf('skipoint_rate')).toFixed(2)} /> */}
          {/* <StatsCard title={'SKI prelevabili'} stat={availPoint(user).toFixed(0)} icon={<FaWallet size={'2em'} />} state={state} showUsd={true} showPoint={false} user={user} alreadyWithdrawn={(parseFloat(user.totalPoint) * getConf('skipoint_rate') - availPoint(user) * getConf('skipoint_rate')).toFixed(2)} /> */}
        {/* <StatsCard title={'Accumulated points'} stat={parseFloat(formatEther(accumulatedPoint.valueOf())).toFixed(0)} icon={<FaPiggyBank size={'3em'} />} />
        <StatsCard title={'Availibility'} stat={parseFloat(formatEther(availibility.valueOf())).toFixed(0)} icon={<FaMoneyCheckAlt size={'3em'} />} /> */}
        {/* <StatsCard title={'Who speak'} stat={'100 different languages'} /> */}
      {/* </SimpleGrid> */}
    </Box>
    </>
  ) : (
    <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <chakra.h1
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
          fontWeight={'bold'}>
          {/* Loading data */}
          Caricamento dati
      </chakra.h1>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}>
        {/* <StatsCard title={'Wallet'} stat={account != null ? account : ''} icon={<FaWallet size={'3em'} />} /> */}
        {/* <StatsCard title={'Accumulated points'} stat={accumulatedPoint.toString()} /> */}
        {/* <StatsCard title={'Accumulated points'} stat={parseFloat(user.totalPoint).toFixed(0)} icon={<FaPiggyBank size={'3em'} />} usd_ski_rate={usd_ski_rate} />
        <StatsCard title={'Availibility'} stat={parseFloat(user.lastbalance).toFixed(0)} icon={<FaPiggyBank size={'3em'} />} usd_ski_rate={usd_ski_rate} /> */}
        {/* <StatsCard title={'Accumulated points'} stat={parseFloat(formatEther(accumulatedPoint.valueOf())).toFixed(0)} icon={<FaPiggyBank size={'3em'} />} />
        <StatsCard title={'Availibility'} stat={parseFloat(formatEther(availibility.valueOf())).toFixed(0)} icon={<FaMoneyCheckAlt size={'3em'} />} /> */}
        {/* <StatsCard title={'Who speak'} stat={'100 different languages'} /> */}
      </SimpleGrid>
    </Box>) ;
}