import { useState } from "react";
import {
  Box,
  Button,
  // Flex,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  // ModalFooter,
  ModalBody,
  ModalCloseButton,
  // Text,
  FormControl,
  InputGroup,
  // InputLeftElement,
  Input
} from "@chakra-ui/react";
// import { ExternalLinkIcon, CopyIcon } from "@chakra-ui/icons";
import { useEthers } from "@usedapp/core";
// import Identicon from "./Identicon";
import Loading from "../utils/Loading";
import { walletAddress } from "../utils/WalletUtils";

type Props = {
  isOpen: any;
  onClose: any;
  updateWallet: any;
  state: any;
};

export default function AccountModal({ isOpen, onClose, updateWallet, state }: Props) {
  const { deactivate } = useEthers();
  const [walletValue, setWalletValue] = useState(walletAddress(state.user));

  function handleDeactivateAccount() {
    deactivate();
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent
        background="gray.900"
        border="1px"
        borderStyle="solid"
        borderColor="gray.700"
        borderRadius="3xl"
      >
        <ModalHeader color="white" px={4} fontSize="lg" fontWeight="medium">
          {/* Your ERC20 wallet address */}
          Indirizzo wallet ERC20
        </ModalHeader>
        <ModalCloseButton
          color="white"
          fontSize="sm"
          _hover={{
            color: "whiteAlpha.700",
          }}
        />
        <ModalBody pt={0} px={4}>
          <Box
            borderRadius="3xl"
            border="1px"
            borderStyle="solid"
            borderColor="gray.600"
            px={5}
            pt={4}
            pb={2}
            mb={3}
          >
            {/* <Flex justifyContent="center" alignItems="center" mb={4}> */}
            { !state.onSubmit ? (
              <form>
                <Stack
                  spacing={4}
                  p="1rem"
                  //   backgroundColor="whiteAlpha.900"
                  boxShadow="md"
                  w={'100%'}
                >
                  <FormControl>
                    <InputGroup>
                      {/* <InputLeftElement
                        pointerEvents="none"
                      // children={<CFaUserAlt color="gray.300" />}
                      // size={'lg'}
                      /> */}
                      <Input
                        type="wallet"
                        // placeholder="ERC20 wallet address"
                        placeholder="Indirizzo wallet ERC20"
                        onChange={ (e)=> setWalletValue(e.currentTarget.value) }
                        defaultValue={ walletAddress(state.user) }
                      />
                    </InputGroup>
                  </FormControl>
                  <Button
                    onClick={ async () => {
                      await updateWallet(walletValue)
                      handleDeactivateAccount()
                      // deactivate();
                      // onClose()
                    }}
                    fontSize="lg"
                    fontWeight="medium"
                    borderRadius="xl"
                  // border="2px solid transparent"
                  // _hover={{
                  //     borderColor: "yellow.700",
                  //     color: "yellow.400",
                  // }}
                  // _active={{
                  //     backgroundColor: "yellow.800",
                  //     borderColor: "yellow.700",
                  // }}
                  >
                    {/* Update */}
                    Aggiorna
                  </Button>
                </Stack>
              </form>
              ) : (
                // <></>
                // <Loading label='Updating...' size='120px' />
                <Loading label='In caricamento...' size='120px' />
              )
              }
            {/* </Flex> */}
          </Box>
        </ModalBody>

        {/* <ModalFooter
          justifyContent="end"
          background="gray.900"
          borderBottomLeftRadius="3xl"
          borderBottomRightRadius="3xl"
          p={6}
        >
          <Text
            color="white"
            textAlign="left"
            fontWeight="medium"
            fontSize="md"
          >
            
          </Text>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
}
