import React from "react";
import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom"
import Main from './Components/Main'
import './index.css';
// import reportWebVitals from './reportWebVitals';
import { DAppProvider } from "@usedapp/core";

ReactDOM.render(
    <React.StrictMode>
        <DAppProvider config={{}}>
            {/* <BrowserRouter> */}
                <Main />
            {/* </BrowserRouter> */}
        </DAppProvider>
    </React.StrictMode>,
    document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
