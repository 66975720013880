
import {
  Box,
  // chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useColorModeValue,
  CircularProgress,
  // CircularProgressLabel,
  Text,
  // createStandaloneToast,
  // useToast,
} from '@chakra-ui/react';
// import { GetAccount } from "./ConnectButton";
// import { formatEther } from "@ethersproject/units";
import { useState } from 'react';
// import { FaDeezer, FaGem, FaFunnelDollar, FaHandHoldingUsd, FaMoneyCheckAlt, FaPiggyBank, FaTicketAlt, FaWallet } from "react-icons/fa";
import { getConf } from '../config'
import { userSuccess } from "../utils/WalletUtils";
import { walletAddress, hasWallet, availWithdraw, canWithdraw } from "../utils/WalletUtils";
import { useEthers } from "@usedapp/core";

// function withdraw(state: any) {
//   await state.withdraw;
// }

interface WProps {
  state: any;
  // inWithdraw: boolean;
  usd_ski_rate: number;
}

function WButton(props: WProps) {
  // const { state, inWithdraw, usd_ski_rate } = props;
  const { state, usd_ski_rate } = props;
  // console.log('WButton usd_ski_rate', usd_ski_rate);
  // const bgGradient = state.success ? 'linear(to-l, red.500, yellow.500)' : 'linear(to-l, gray.400, gray.400)';
  // const hoverBgGradient = state.success ? 'linear(to-r, yellow.500, yellow.500)' : 'linear(to-r, gray.500, gray.500)';
  const from = 'gray.800';
  const to = 'gray.200';
  const color = useColorModeValue(from, to);
  const colorInv = useColorModeValue(to, from);
  // const availWtdrw = (parseFloat(state.user.point)*conf.skipoint_rate).toFixed(2)
  const availWtdrw = availWithdraw(state.user)
  // const availWtdrw = (parseFloat('10000')*SKIPOINT_RATE).toFixed(2)
  // let state.inWithdraw = true
  // console.log('WButton availWtdrw', availWtdrw);
  return !state.inWithdraw ? (
    <Box pl={{ base: 2, md: 4 }}>
      <StatLabel fontSize={{ base: '1.2em', md: '1.8em' }} fontWeight={'medium'} isTruncated textAlign={'center'}>
      { availWtdrw > 0
          ? <>
            {
              !canWithdraw(state.user, usd_ski_rate) ?
                // <Text> Withdraw unvailable</Text>
                <Text> Conversione non disponibile</Text>
                :
                // <Text>Withdraw <br />{availWtdrw.toFixed(2)} {getConf('default_ens')}  = ~{(availWtdrw * usd_ski_rate).toFixed(2)} {getConf('default_stable')}</Text>
                // <Text>Clicca per convertire  <br />{parseFloat(state.user.point).toFixed(0)} Punti in {availWtdrw.toFixed(2)} {getConf('default_ens')}  (~{(availWtdrw * usd_ski_rate).toFixed(2)} {getConf('default_stable')})</Text>
                <Text mb='6px'>Clicca per convertire  <br />{parseFloat(state.user.point).toFixed(0)} Punti in {availWtdrw.toFixed(2)} {getConf('default_ens')}</Text>
            }
          </>
          // : <Text>No withdraw availibility</Text>
          : <Text>Nessun punto da convertire in SKI</Text>
      }
      </StatLabel>
      {
        !canWithdraw(state.user, usd_ski_rate) ?
          <></>
          :
          <StatNumber fontSize={{ base: '0.8em', md: '1em' }} fontWeight={'medium'} textAlign={'center'} >
            {/* {(state.user != undefined ? state.user.wallet : '') == '' ? 'Insert your wallet' : 'to '+(state.user != undefined ? state.user.wallet : '')} */}
            {/* {hasWallet(state.user) ? 'to ' + walletAddress(state.user) : 'Undefined ERC20 wallet'} */}
            {/* {hasWallet(state.user) ? 'to ' + walletAddress(state.user) : 'Wallet ERC20 non definito'} */}
            {hasWallet(state.user) ? 'sul tuo wallet ' + walletAddress(state.user) : 'Wallet ERC20 non definito'}
          {/* { state.user.lastbalance > 0
              ? <Text>Withdraw {availWtdrw} { DEFAULT_ENS }  = {(parseFloat(availWtdrw)*usd_ski_rate).toFixed(2)} USD</Text>
              : <Text>No withdraw availability</Text>
          } */}
          {/* {() => {
                console.log('clicked');
                if (state.user.lastbalance > 0) {
                  return 'Withdraw '+availWtdrw+' '+DEFAULT_ENS;
                } else return 'No availabe withdraw'
              }} */}
            {/* Withdraw {availWtdrw} { DEFAULT_ENS } */}
          </StatNumber>
      }
      {
        !canWithdraw(state.user, usd_ski_rate) ?
        <>
            {hasWallet(state.user) ? <StatHelpText fontWeight={'medium'} textAlign={'center'} >
              {/* {availWtdrw > 0 && 'Availibility ' + availWtdrw.toFixed(2) + ' ' + getConf('default_ens') + ' (~' + (availWtdrw * usd_ski_rate).toFixed(2) + ' ' + getConf('default_stable') + ') is less than minimum allowed ' + getConf('min_withdraw') + ' ' + getConf('default_stable')} */}
              {availWtdrw > 0 && availWtdrw.toFixed(2) + ' ' + getConf('default_ens') + ' = ~' + (availWtdrw * usd_ski_rate).toFixed(2) + ' ' + getConf('default_stable') + ' sono inferiori al minimo valore di ' + getConf('min_withdraw') + '.00 ' + getConf('default_stable') + ' convertibili'}
             </StatHelpText> : 
             <StatHelpText fontWeight={'medium'} textAlign={'center'} >
               {/* Click to set up your ERC20 wallet */}
                {state.hasMetamask ? 'Clicca per connettere metamask' : 'Clicca per inserire il tuo indirizzo wallet ERC20'}
             </StatHelpText>}
          {/* because availibility {availWtdrw.toFixed(2)} { conf.default_ens } ({(availWtdrw*usd_ski_rate).toFixed(2)} {conf.default_stable}) is less than {conf.min_withdraw} {conf.default_stable} */}
        </>
          :
          <></>
      }
      {/* {
        !canWithdraw(state.user, usd_ski_rate) ?
          {
            hasWallet(state.user) ? 
            <></>
             : 
             <StatHelpText fontWeight={'medium'} textAlign={'center'} >
               because availibility {availWtdrw.toFixed(2)} { conf.default_ens } ({(availWtdrw*usd_ski_rate).toFixed(2)} {conf.default_stable}) is less than {conf.min_withdraw} {conf.default_stable}
             </StatHelpText>
          }
          :
          <></>
      } */}
    </Box>
  ) : (
    <>
    <Box pl={{ base: 2, md: 4 }}>
      <StatLabel fontSize={'3xl'} fontWeight={'medium'} isTruncated>
      {/* Processing withdrawl... */}
            Conversione in corso...
      </StatLabel>
      {/* <StatNumber fontSize={'medium'} fontWeight={'medium'}>
        {stat} = {(parseFloat(stat)*SKIPOINT_RATE).toFixed(2)} {DEFAULT_ENS} = {(parseFloat(stat)*SKIPOINT_RATE*usd_ski_rate).toFixed(2)} USD
      </StatNumber> */}
    </Box>
    <Box
      my={'auto'}
      color={color}
      alignContent={'center'}>
       <CircularProgress isIndeterminate
        // thickness="5px"
        // speed="3s"
        // emptyColor="gray.200"
        trackColor="yellow.500"
        color={colorInv}
        // size="xs"
      >
        {/* <CircularProgressLabel fontSize={'1xl'}>Processing...</CircularProgressLabel> */}
      </CircularProgress>
    </Box>
    </>
  );
}

interface WithdrawProps {
  usd_ski_rate: number;
  state: any;
  handleOpenModal: any;
}

export default function WithdrawButton(props: WithdrawProps) {
  const { state, usd_ski_rate, handleOpenModal } = props;
  const _canWithdraw = canWithdraw(state.user, usd_ski_rate)
  // const bgGradient = _canWithdraw ? 'linear(to-l, red.500, yellow.500)' : 'linear(to-r, gray.700, gray.600)';
  // const hoverBgGradient = _canWithdraw ? 'linear(to-l, #FF0080, yellow.400)' : 'linear(to-r, gray.700, gray.500)';
  const bgGradient = _canWithdraw ? 'linear(to-l, red.500, yellow.500)' : 'linear(to-l, gray.400, yellow.800)';
  const hoverBgGradient = _canWithdraw ? 'linear(to-l, #FF0080, yellow.400)' : 'linear(to-l, gray.400, yellow.800)';
  const from = _canWithdraw ? '#FF0080' : 'gray.500';
  const to = _canWithdraw ? '#FF0080' : 'gray.500';
  const color = useColorModeValue(from, to);
  const colorInv = useColorModeValue(from, to);
  const { activateBrowserWallet } = useEthers();
  // function metamaskWithdraw() {
  //   console.log('metamaskWithdraw');
  //   activateBrowserWallet();
  //   state.withdraw()
  // }
  // const availWtdrw = (parseFloat(state.user.lastbalance)*SKIPOINT_RATE).toFixed(2)
  // state.inWithdraw = true
  // const [inWithdraw, setInWithdraw] = useState(false);
  // console.log('inWithdraw', inWithdraw);
  // return state.success && !state.withdrawn && state.user.lastbalance > 0 ? (
  // return state.success && !state.withdrawn ? (
  return userSuccess(state.user) && !state.withdrawn ? (
    <Box maxW="3xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      {/* <chakra.h1
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}>
        Withdraw
      </chakra.h1> */}
      <SimpleGrid columns={{ base: 1, md: 1 }} spacing={{ base: 5, lg: 8 }}>
        <Stat
          px={{ base: 4, md: 8 }}
          py={'5'}
          shadow={'xl'}
          border={'1px solid'}
          borderColor={colorInv}
          rounded={'lg'}
          opacity={10}
          bgGradient={bgGradient}
          _hover={{
            bgGradient: hoverBgGradient,
          }}
          onClick={() => {
            // console.log('clicked');
          _canWithdraw ?
              hasWallet(state.user) ? state.withdraw() : state.hasMetamask ? activateBrowserWallet() : handleOpenModal()
              :
              state.hasMetamask ? activateBrowserWallet() : handleOpenModal()
            // if (_canWithdraw) {
            //   hasWallet(state.user) ? state.withdraw() : state.hasMetamask ? activateBrowserWallet() : handleOpenModal();
            //   // Withdraw(inWithdraw);
            //   // if (!inWithdraw) await state.withdraw();
            //   // if (!inWithdraw) {
            //     // handleOpenModal();
            //     // state.withdraw();
            //   // }
            //   // setInWithdraw(true);
            // }
          }}
          cursor='pointer'
          // color={color}
          >
          <Flex justifyContent={'center'}>
              {/* <Box pl={{ base: 2, md: 4 }}>
                <StatLabel fontSize={'xs'} fontWeight={'medium'} isTruncated>
                  
                </StatLabel>
                <StatNumber fontSize={'3xl'} fontWeight={'medium'}>
                  Withdraw {availWtdrw} { DEFAULT_ENS }
                </StatNumber>
              </Box> */}
              {/* <WButton state={state} inWithdraw={inWithdraw} usd_ski_rate={usd_ski_rate} /> */}
              <WButton state={state} usd_ski_rate={usd_ski_rate} />
          </Flex>
        </Stat>
      </SimpleGrid>
    </Box>
  ) : (
    <></>
  );
}
