
import { createStandaloneToast } from '@chakra-ui/react'
import { myTheme } from '../theme';
import * as wUtils from '../utils/WalletUtils'

export const Notification = (title, description, status, duration = 5000) => {
    const toast = createStandaloneToast({ theme: myTheme });
    toast({
        title: title,
        description: description,
        status: status,
        duration: duration,
        position: 'top',
        isClosable: true,
      // variant: 'top-accent'
      });
  
    return <></>;
  };

export const toastUser = (user) => {
  // console.log('toastUser user', user);
  // console.log('toastUser message', getUserMessage(user));
  // console.log('toastUser error', getUserError(user));
  wUtils.userSuccess(user) ? Notification(getUserMessage(user), '', 'success') : Notification('Error.', getUserError(user), 'error');
}

export const getUserMessage = (user) => {
  // console.log('getUserMessage user', user);
  // console.log('getUserMessage user.success', wUtils.userSuccess(user));
  // console.log('getUserMessage user.message.lenght', user.message.length);
  return wUtils.userSuccess(user) ? (user.message.length > 0 ? user.message[0] : 'Success') : 'Success'
}

export const getUserError = (user) => {
  // console.log('getUserError user', user);
  // console.log('getUserError user.success', wUtils.userSuccess(user));
  // console.log('getUserError user.error.lenght', user.error.length);
  // console.log('getUserError user.error[0]', user.error[0]);
  return wUtils.isUserValid(user) ? (user.error.length > 0 ? user.error[0] : 'Error') : 'Error'
}