import {
  Box,
  // chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  // VisuallyHidden,
  Flex,
  Link,
  Heading,
  Spacer,
  Image,
  Center
} from '@chakra-ui/react';
// import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
// import { ReactNode } from 'react';
import { COPY, FISCAL } from '../config'

// const SocialButton = ({
//   children,
//   label,
//   href,
// }: {
//   children: ReactNode;
//   label: string;
//   href: string;
// }) => {
//   return (
//     <chakra.button
//       bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
//       rounded={'full'}
//       w={8}
//       h={8}
//       cursor={'pointer'}
//       as={'a'}
//       href={href}
//       display={'inline-flex'}
//       alignItems={'center'}
//       justifyContent={'center'}
//       transition={'background 0.3s ease'}
//       _hover={{
//         bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
//       }}>
//       <VisuallyHidden>{label}</VisuallyHidden>
//       {children}
//     </chakra.button>
//   );
// };
interface FooterProps {
  copy: string;
  availibility: BigInt;
  user: string;
}
export default function Footer(props: FooterProps) {
  return (
    <>
      <Box
        // bg={useColorModeValue('gray.50', 'gray.800')}
        bg={useColorModeValue('abtg.white', 'abtg.white')}
        color={useColorModeValue('gray.700', 'gray.200')}
        width="100%"
        pos={'fixed'}
        left={0}
        bottom={0}
        right={0}
        // height={"35px"}
        //  left="0"
        // textAlign={'center'}
      >
        <Flex
          p={{ base: 3, md: 4 }}
          w="100%"
          direction={{ base: 'column', md: 'row' }}
        >
          <Box>
            <Link href='/'>
              <Image ml={'auto'} mr={'auto'} w={{ base: '100px', md: '150px' }} src='ABTGlogo.png' alt='ABTG game' />
            </Link>
          </Box>
          <Spacer mb={1} />
          <Box textAlign={'center'}>
            {/* <Center> */}
            <Text fontSize={{md: '0.7em', lg: '0.9em', xl: '1.1em', base: '0.55em'}} textColor={'gray.500'} mt={'-3px'} ml={'20px'} mr={'20px'}>{COPY} {FISCAL}
              {/* <Text textColor={'gray.300'} fontSize={'0.8em'} mt={{ xl: "-6px", base: "-1px" }}>developed by <Link href='https://www.dyncode.it' target={'_blank'}>DYNcode srl</Link></Text> */}
              </Text>
            {/* </Center> */}
            {/* <Text fontSize={'0.8em'} textColor={'gray.400'}>{FISCAL}</Text> */}
          </Box>
          <Spacer mb={1} />
          <Box ml={'auto'} mr={'auto'}>
            <Flex direction={'row'}>
              <Link href='https://www.facebook.com/AlfioBardollaTrainingGroup/' target="_blank" pr={'5px'}>
                <Image h={{ base: '15px', md: '25px', lg: '30px' }} src='FB.png' alt='Facebook' />
              </Link>
              <Link href='https://www.instagram.com/alfiobardolla/' target="_blank" pr={'5px'}>
                <Image h={{ base: '15px', md: '25px', lg: '30px' }} src='IG.png' alt='Instagram' />
              </Link>
              <Link href='https://www.youtube.com/channel/UC5I-y7dx9z9FPAeOheFc6iQ' target="_blank" pr={'5px'}>
                <Image h={{ base: '15px', md: '25px', lg: '30px' }} src='YT.png' alt='YouTube' />
              </Link>
              <Link href='https://www.linkedin.com/in/alfiobardolla/' target="_blank" pr={'5px'}>
                <Image h={{ base: '15px', md: '25px', lg: '30px' }} src='IN.png' alt='Linkedin' />
              </Link>
              {/* <Link href='https://www.facebook.com/AlfioBardollaTrainingGroup/' target="_blank">
                <Image h={{ base: '20px', md: '25px', lg: '30px' }} src='TW.png' alt='Twitter' />
              </Link> */}
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  );
}