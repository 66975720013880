// import { USD_SKI_RATE_API } from '../config'
import { getConf } from '../config';
// import { Notification } from './Notification';

const api = process.env.REACT_APP_ATTR_API_URL || 'http://localhost:5002';
// const apiUrl = 'https://middleware-dev.alfiobardolla.com';
// const apiMiddlewareUrl = 'https://token.alfiobardolla.com';
// const apiUrl = conf.middleware_api;
// const apiMiddlewareUrl = conf.dex_url;
// const apiUrl = getConf('middleware_api');
// const apiMiddlewareUrl = getConf('dex_url');
// const USD_SKI_RATE_API = conf.usd_ski_rate_api;

// let token = localStorage.token

// if (!token)
//   Math.random().toString(36).substr(-8)

// const headers = {
//   'Accept': 'application/json',
//   'Authorization': token
// }

// export const getAll = () =>
//   fetch(`${api}/attractions`, { headers })
//     .then(res => res.json())
//     .then(data => data.attractions)

// export const remove = (attraction) =>
//   fetch(`${api}/attractions/${attraction.id}`, { method: 'DELETE', headers })
//     .then(res => res.json())
//     .then(data => data.attraction)

// export const create = (body) =>
//   fetch(`${api}/attractions`, {
//     method: 'POST',
//     headers: {
//       ...headers,
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(body)
//   }).then(res => res.json())

export const getUser = () =>
  fetch(`${getConf('middleware_api')}/api/game/user`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(res => res.json())

// export const loginWithToken = (token) =>
// fetch(`${apiUrl}/api/game/user`, {
//   method: 'GET',
//   headers: {
//     'Accept': 'application/json',
//     'Authorization': 'Bearer '+token,
//     'Content-Type': 'application/json'
//   }
// }).then(res => res.json())

/*
/{_locale}/authToken/{token}
*/


export const loginWithToken = async (token) => {
  // const {conf} = await settings();
  // const url = getConf('dex_url');
  // return fetch(`${apiMiddlewareUrl}/it/API/authToken/` + token, {
  return fetch(`${getConf('dex_url')}/it/API/authToken/` + token, {
    method: 'GET',
    // headers: {
    //   'Accept': 'application/json',
    //   'Authorization': 'Bearer '+token,
    //   'Content-Type': 'application/json'
    // }
  }).then(res => res.json())
}

export const skiUsdRate = () =>
// fetch(`${USD_SKI_RATE_API}`, {
  // fetch('https://bsc.api.0x.org/swap/v1/quote?buyToken=BUSD&sellToken=0x7f692c05058f1C77c87413a0591c7a237090DA00&sellAmount=1000000000000000000&excludedSources=BakerySwap,Belt,DODO,DODO_V2,Ellipsis,Mooniswap,MultiHop,Nerve,SushiSwap,Smoothy,ApeSwap,CafeSwap,CheeseSwap,JulSwap,LiquidityProvider', {
fetch(`${getConf('usd_ski_rate_api')}`, {
    method: 'GET',
  }).then(res => res.json())

export const withdrawAPI = (token) => {
  // console.log('withdrawAPI token', token);
    return fetch(`${getConf('dex_url')}/it/API/withdraw/` + token, {
    method: 'GET'
  }).then(res => res.json())
}

export const loginWithEmailPassword = (email, password, tel) => {
  let formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);
  formData.append('tel', tel);
  return fetch(`${getConf('dex_url')}/it/API/authToken`, {
    method: 'POST',
    // headers: new Headers({
    //            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    //            'Access-Control-Allow-Origin': 'http://localhost:3000',

    //   }),
    // body: "email="+email+"&password="+password
    // body: JSON.stringify({ email, password }),
    body: formData,
    // headers: {
    //   'Accept': 'application/json',
    //   'Authorization': 'Bearer '+token,
    //   'Content-Type': 'application/json'
    // }
  }).then(res => res.json())
}

export const updateWallet = (token, wallet) => {
  // Notification('updateWallet.', 'token='+token+' - wallet ="'+wallet+'"', 'error', 1000000);
  // console.log('API updateWallet token', token);
  // console.log('API updateWallet wallet', wallet);
  let formData = new FormData();
  formData.append('token', token);
  formData.append('wallet', wallet);
  return fetch(`${getConf('dex_url')}/it/API/apiUpdateWallet`, {
    method: 'POST',
    body: formData,
  }).then(res => res.json())
}


