import React from "react";
import { Button, Box, Text } from "@chakra-ui/react";
// import { shortenIfAddress } from "@usedapp/core";
import { shortAddress, hasWallet } from "../utils/WalletUtils";
// import * as wUtils from '../utils/WalletUtils'

const WalletButton = (props) => {

  // const userWallet = props.user != undefined ? props.user.wallet : '';


  // const shortAddress = (user) => {
  //   try {
  //     return shortenIfAddress(user != undefined ? user.wallet : '')
  //     } catch (error) {
  //      return user != undefined ? user.wallet : ''
  //     }
  // }

  return (
    <Box
    display="flex"
    alignItems="center"
    background="gray.700"
      borderRadius="xl"
      py="0"
      mt={{ base: '-35px', md: '-40px' }}
      h={{ base: '30px', md: '38px' }}
  >
    {/* <Box px="3">
      <Text color="white" fontSize="xs">
        {tokenBalance && parseFloat(formatEther(tokenBalance)).toFixed(3)} SKI
      </Text>
    </Box> */}
    <Button
      onClick={props.handleOpenModal}
      bg="gray.800"
      border="1px solid transparent"
      _hover={{
        border: "1px",
        borderStyle: "solid",
        borderColor: "blue.400",
        backgroundColor: "gray.700",
      }}
      borderRadius="xl"
        m="0px"
        px={3}
        h={{ base: '30px', md: '38px' }}
    >
        <Text color="white" fontSize={{ base: '0.6em', md: 'xs' }} fontWeight="medium" mr="0">
        {/* {account &&
          `${account.slice(0, 6)}...${account.slice(
            account.length - 4,
            account.length
          )}`} */}
          {/* {(props.user != undefined ? props.user.wallet : '') == '' ? 'Insert your wallet' : 'Wallet: '+shortAddress(props.user )} */}
          {/* {hasWallet(props.user) ? shortAddress(props.user) : 'Set your ERC20 wallet'} */}
          {hasWallet(props.user) ? shortAddress(props.user) : 'Inserisci il tuo wallet ERC20'}
          
          {/* Wallet: { shortenIfAddress(props.user != undefined ? props.user.wallet : '') } */}
      </Text>
      {/* <Identicon /> */}
    </Button>
  </Box>
);
}

export default WalletButton
