import DetailsCards from "./DetailsCards";
import TransactionCards from "./TransactionCards"
import WithdrawButton from "./WithdrawButton";
import UsdSkiRate from "./UsdSkiRate";
import Loading from "../utils/Loading";
import {
  // Box,
  // chakra,
  // Flex,
  // SimpleGrid,
  // Stat,
  // StatLabel,
  // StatNumber,
  // useColorModeValue,
  // Spinner,
  // CircularProgress,
  // CircularProgressLabel,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
} from '@chakra-ui/react';
import { userSuccess } from "../utils/WalletUtils";
import AccountModal from "./AccountModal";

import { getConf } from '../config'

interface CardProps {
  usd_ski_rate: number;
  user: any;
  state: any;
  updateWallet: any
}
export default function Body(props: CardProps) {
  const { usd_ski_rate, user, state, updateWallet } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  // console.log('withdrawls', state.withdrawls);
  // const bgGradient = '';
  // const hoverBgGradient = '';
  // const from = 'gray.800';
  // const to = 'gray.200';
  // const color = useColorModeValue(from, to);
  // const colorInv = useColorModeValue(to, from);
  // return state.success ? (
  return userSuccess(state.user) ? (
    <>
      <UsdSkiRate state={state} />
      {getConf('env') !== 'main' &&
      <>
      {/* <Alert status='error'>
        <AlertIcon />
          <AlertTitle>Do not Withdraw!</AlertTitle>
        <AlertDescription>The system are setted to distribute SKI on Binance test network.</AlertDescription>
      </Alert> */}
        <Alert
          status='warning'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          // height='200px'
        >
          <AlertTitle mt={2} mb={0} fontSize='1xl' color={'red.300'}>
            -- AMBIENTE DI TEST --
          </AlertTitle>
          <AlertIcon boxSize='40px' mr={0} />
          {/* <AlertDescription maxWidth='lg'>
            -- BINANCE TEST NETWORK --
          </AlertDescription> */}
          <AlertTitle mt={2} mb={1} fontSize={{ base: '2xl', md: '3xl' }} color={'red'}>
            {/* Do not Withdraw */}
            NON CONVERTIRE I TUOI PUNTI
          </AlertTitle>
          {/* <AlertDescription maxWidth='lg'>
            -- TEST ENV --
          </AlertDescription> */}
          <AlertDescription maxWidth='lg' fontSize='sm'>
            {/* The system are settled to distribute SKI on Binance test network. */}
            Il sistema è impostato per distribuire SKI sulla test network di Binance
          </AlertDescription>
          <AlertDescription maxWidth='lg' fontSize='sm'>
            {/* Please come back in the next few days to be able to obtain SKI token on Binance Smart Chain network. */}
            Vi invitiamo a tornare nei prossimi giorni per poter ottenere SKI sulla main network di Binance.
          </AlertDescription>
        </Alert>
      </>
    }
    <DetailsCards usd_ski_rate={usd_ski_rate} user={user} state={state}/>
    <WithdrawButton state={state} usd_ski_rate={usd_ski_rate} handleOpenModal={onOpen} />
    {state.hasMetamask ? 
      <></>
        : 
        <AccountModal isOpen={isOpen} onClose={onClose} updateWallet={updateWallet} state={state} />
    }
    <TransactionCards usd_ski_rate={usd_ski_rate} user={user} state={state}/>
    </>
  ) : (
    // <Loading label='Updating...' size='120px' />
    <Loading label='In caricamento...' size='120px' />
  );
}