
import {
  Box,
  Link,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Spacer,
  Image
} from '@chakra-ui/react';
// import { GetAccount } from "./ConnectButton";
// import { formatEther } from "@ethersproject/units";
import { ReactNode } from 'react';
// import { FaDeezer, FaGem, FaFunnelDollar, FaHandHoldingUsd, FaMoneyCheckAlt, FaPiggyBank, FaTicketAlt, FaWallet } from "react-icons/fa";
import { FaPiggyBank, FaWallet } from "react-icons/fa";
import { getConf } from '../config'
import { userSuccess } from "../utils/WalletUtils";


interface CardProps {
  state: any;
}

export default function UsdSkiRate(props: CardProps) {
  // const { account } = GetAccount();
  const { state } = props;
  // console.log('DetailsCards', state.conf.default_network_name);
  // state.user.withdrawls.map((w: any) => {
  //   // return <StatsCard key={index} date={item.checkDate} amount={item.gamepoint.toFixed(2).toString()} ip={item.requestip} transactionHash={item.txid} success={item.success} />
  //   console.log(w.checkDate)
  // });
  // return state.success ? (
    const borderColor = useColorModeValue('gray.800', 'gray.500');
  return userSuccess(state.user) ? (
    // <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      // <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}>
        <Stat
          px={{ base: 2, md: 4 }}
          py={'3'}
          // shadow={'xl'}
          // border={'1px solid'}
          // borderColor={borderColor}
          rounded={'lg'}
          // bgGradient={'linear(to-r, gray.900, gray.800)'}
          _hover={{
            // bgGradient: 'linear(to-r, red.500, yellow.500)',
            bgGradient: 'linear(to-r, gray.800, gray.700)',
          }}
          placement="right"
        >
          <Flex>
              <Spacer />
            <Box pl={{ base: 0, md: 0 }} placement="right">
              {/* <StatLabel fontSize={'0.9rem'} fontWeight={'medium'} isTruncated>
                <Image
                  boxSize='10%'
                  // objectFit='cover'
                  src='/coingecko_logo.png'
                  alt='Dan Abramov'
                />
              </StatLabel> */}
          <StatNumber fontSize={{ base: '0.6em', md: '0.8em' }} fontWeight={'medium'} placement="right">
            {/* <Link href='https://www.coingecko.com/it/monete/skillchain' target='_blank'>Coingecko: 1 {getConf('default_ens')} {'= ~' + (state.usd_ski_rate).toFixed(8)} {getConf('default_stable')}</Link> */}
            Pancakeswap V.2: 1 {getConf('default_ens')} {'= ~' + (state.usd_ski_rate).toFixed(8)} {getConf('default_stable')}
              </StatNumber>
            </Box>
            {/* <Box
          my={'auto'}
          color={useColorModeValue('gray.800', 'gray.200')}
          alignContent={'center'}>
          {icon}
        </Box> */}
          </Flex>
        </Stat>
      // </SimpleGrid>
    // </Box>
  ) : (
    <></>);
}