import { useState } from "react";
import {
    Flex,
    Heading,
    Input,
    Button,
    InputGroup,
    Stack,
    InputLeftElement,
    chakra,
    Box,
    Text,
    Divider,
    FormControl,
    // FormHelperText,
    InputRightElement,
    // useColorModeValue
    Link,
    Image,
    Spacer
} from "@chakra-ui/react";
import { FaUserAlt, FaLock, FaPhone, FaMobileAlt } from "react-icons/fa";
import Loading from "../utils/Loading";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
const CFaPhone = chakra(FaMobileAlt);


interface LoginProps {
    loginWithCredential: any;
    state: any;
  }
  
const Login = (props: LoginProps) => {
    // const bg = useColorModeValue('red.200', 'red.500')
    // const bg = useColorModeValue('red.200', 'red.500')
    const { loginWithCredential, state } = props;
    const [showPassword, setShowPassword] = useState(false);
    // const [onSubmit, setOnSubmit] = useState(false);

    const handleShowClick = () => setShowPassword(!showPassword);
    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [telValue, setTelValue] = useState(state.tel);
    return state.onSubmit ? (
        <Loading label='Authorizing...' size='120px' />
    ) : (
        <Flex
            // flexDirection="column"
            // width="100wh"
            // height="60vh"
            //   backgroundColor="gray.200"
            justifyContent="center"
                alignItems="center"
                mt='25px'
        >
            
            <Stack
                flexDir="column"
                mb="2"
                justifyContent="center"
                alignItems="center"
                >
                    {/* <Box ml={'auto'} mr={'auto'}>
                        <Flex direction={'row'}>
                            <Link href='https://www.facebook.com/AlfioBardollaTrainingGroup/' target="_blank" pr={'5px'}>
                                <Image h={{ base: '35px', md: '35px', lg: '40px' }} src='googlePlay.png' alt='Facebook' />
                            </Link>
                            <Spacer />
                            <Link href='https://www.instagram.com/alfiobardolla/' target="_blank">
                                <Image h={{ base: '35px', md: '35px', lg: '40px' }} src='appleStore.png' alt='Instagram' />
                            </Link>
                        </Flex>
                    </Box> */}
                    {/* <Avatar bg="yellow.600" /> */}
                    <Text
                        fontSize={'5xl'}
                        fontWeight='bold'
                        // mb='-20px'
                    >MONEY GAME</Text>
                    <Text
                        fontSize={'2xl'}
                        mt={'-15px !important'}
                    >(L)earning Playing</Text>
                    <Divider opacity={1} borderColor={'abtg.white'} />
                    <Stack direction={['column', 'row']} mb={"-15px !important"}>
                        <Text
                            as="span"
                            fontSize={{ base: '2xl', sm: '3xl' }}
                            fontWeight='bold'
                            color="abtg_orange.lighter"
                    >Converti i tuoi punti
                        <Text
                            as="span"
                            fontSize={{ base: '2xl', sm: '3xl' }}
                            fontWeight='bold'
                            color="abtg.white"
                        >&nbsp;in</Text></Text>
                    </Stack>
                    <Text
                        as="span"
                        fontSize={{ base: '2xl', sm: '3xl' }}
                        fontWeight='bold'
                        color="abtg.white"
                    >Skillchain</Text>
                    
                    
                <Box boxSize="sm">
                    <form>
                        <Stack
                            spacing={4}
                            p="1rem"
                            //   backgroundColor="whiteAlpha.900"
                            // boxShadow="md"
                        >
                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                            children={<CFaUserAlt color="abtg.gray" />}
                                    />
                                        <Input type="email"
                                            textColor="gray.700"
                                            _placeholder={{ color: 'gray.500' }}
                                            background="abtg.white.se" placeholder="Inserisci il tuo indirizzo email"
                                        onChange={(e)=> setEmailValue(e.currentTarget.value)}  />
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        // color="gray.300"
                                            children={<CFaLock color="abtg.gray" />}
                                        // background="abtg_white.base"
                                    />
                                    <Input
                                            type={showPassword ? "text" : "password"}
                                            textColor="gray.700"
                                            _placeholder={{ color: 'gray.500' }}
                                        placeholder="Inserisci la tua password"
                                            onChange={(e) => setPasswordValue(e.currentTarget.value)}
                                            background="abtg.white"
                                    />
                                    <InputRightElement width="4.5rem">
                                        <Button 
                                            h="1.75rem"
                                            size="sm"
                                            onClick={handleShowClick}
                                            // bg={bg}
                                            colorScheme='blue'
                                        >
                                            {showPassword ? "Hide" : "Show"}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                {/* <FormHelperText textAlign="right">
                  <Link>forgot password?</Link>
                </FormHelperText> */}
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<CFaPhone color="abtg.gray" />}
                                        />
                                        <Input type="tel"
                                        value={telValue}
                                            textColor="gray.700"
                                            _placeholder={{ color: 'gray.500' }}
                                            background="abtg.white.se" placeholder="Inserisci il tuo numero di cellulare"
                                            onChange={(e) => setTelValue(e.currentTarget.value)} />
                                    </InputGroup>
                                </FormControl>
                            <Button
                                onClick={() => {
                                        loginWithCredential(emailValue, passwordValue, telValue)
                                }}
                                fontSize="lg"
                                fontWeight="medium"
                                borderRadius="xl"
                                // border="2px solid transparent"
                                _hover={{
                                    // borderColor: "yellow.700",
                                    backgroundColor: "abtg_orange.darker",
                                }}
                                background="abtg_orange.base"
                                // _active={{
                                //     // backgroundColor: "yellow.800",
                                //     backgroundColor: "abtg_orange.base",
                                // }}
                            >
                                Login
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Stack>
            {/* <Box>
        New to us?{" "}
        <Link color="teal.500" href="#">
          Sign Up
        </Link>
      </Box> */}
        </Flex>
    );
};

export default Login;
