
import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Link,
  Text
} from '@chakra-ui/react';
// import { GetAccount } from "./ConnectButton";
// import { formatEther } from "@ethersproject/units";
// import { ReactNode } from 'react';
import { FaLink, FaBan } from "react-icons/fa";
import { getConf } from '../config'
import { userSuccess } from "../utils/WalletUtils";
// import { useApiConf } from '../utils/ApiConf'

interface StatsCardProps {
  date: string;
  amount: string;
  ip: string;
  transactionHash: string;
  success: boolean;
  price: string;
  text: string;
  env: string;
  point: string;
}
function StatsCard(props: StatsCardProps) {
  const { date, amount, ip, transactionHash, success, price, text, env, point } = props;
  // const [from, to] = () => {
  //   return ["#F9FAFB", "gray.600"]
  //   // return 'linear(to-l, #7928CA, #FF0080)';
  // }
  const bgGradientMain = success ? 'linear(to-l, cyan.600, #FF0080)' : 'linear(to-l, gray.800, gray.800)';
  const hoverBgGradientMain = success ? 'linear(to-l, cyan.500, #FF0080)' : 'linear(to-l, gray.800, gray.800)';
  const bgGradientTest = success ? 'linear(to-l, cyan.500, #FF6680)' : 'linear(to-l, gray.800, gray.800)';
  const hoverBgGradientTest = success ? 'linear(to-l, cyan.400, #FF6680)' : 'linear(to-l, gray.800, gray.800)';
  const bgGradient = env==='test' ? bgGradientTest : bgGradientMain;
  const hoverBgGradient = env === 'test' ? hoverBgGradientTest : hoverBgGradientMain;
  // const hoverBgGradient = success ? 'linear(to-r, red.500, yellow.500)' : 'linear(to-r, gray.500, gray.500)';
  const from = success ? 'gray.800' : 'gray.600';
  const to = success ? 'gray.200' : 'gray.600';
  const color = useColorModeValue(from, to);
  const borderColor = success ? 'purple.400' : 'abtg.white';
  const icon = success ? <FaLink size={'2em'} /> : <FaBan size={'1em'} />;
  return (
    <Stat
      px={{ base: 4, md: 8 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={borderColor}
      rounded={'lg'}
      opacity={10}
      bgGradient={bgGradient}
      _hover={{
        bgGradient: hoverBgGradient,
      }}
      color={color}
      // backgroundImage="linear-gradient(135deg, #1175EE 30%, #FF6680 70%), url('/testNetwork.png')"
      // backgroundPosition="50%"
      // backgroundRepeat="repeat"

  //     background-image:"linear-gradient(135deg, #50A68480 30%, #115E6780 90%), url('https://www.mahealthcare.com/assets/images/clinic/NursePhone.jpg')";
  // background - repeat: no - repeat;
  // background - position: 50 %;
      // bgSize={200}
      // bgClip="text"
      >
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontSize={'xs'} fontWeight={'medium'} isTruncated>
            {/* {date} (IP: {ip}) */}
            { text === '' ? date+' (IP: '+ip+')' : ''}
          </StatLabel>
          <StatNumber fontSize={{ base: '1em', md: '1.8em' }} fontWeight={'medium'}>
            {/* {amount} {getConf('default_ens')} (~{price} {getConf('default_stable')}) */}
            {/* {text === '' ? parseFloat(point).toFixed(0) + ' Punti in ' + amount + ' ' + getConf('default_ens') + ' (~' + price + ' ' + getConf('default_stable') + ')' : text} */}
            {text === '' ? parseFloat(point).toFixed(0) + ' Punti in ' + amount + ' ' + getConf('default_ens') : text}
          </StatNumber>
          {/* <StatHelpText fontWeight={'medium'} textAlign={'center'} >
               ~{price}
             </StatHelpText> */}
        </Box>
        { text === '' ? 
        <Box
          my={'auto'}
          color={color}
            alignContent={'center'}>
            {/* {success ? <Link isExternal href={getConf('bsc_explorer') + 'tx/' + transactionHash}>{icon}</Link> : icon} */}
            {success ? <Link isExternal href={getConf('bsc_explorer_' + env) + 'tx/' + transactionHash}>{icon}</Link> : icon}
            {/* {env === 'test' && <Text fontSize={'8px'}>Test network</Text>} */}
          {/* <Link isExternal href={EXPLORER + transactionHash}>{icon}</Link> */}
            {/* {'bsc_explorer_' + env} */}
        </Box>
        : <></> }
      </Flex>
    </Stat>
  );
}

interface CardProps {
  usd_ski_rate: number;
  user: any;
  state: any;
}

export default function TransactionCards(props: CardProps) {
  // console.log('StatsCard', conf.apiconf);
  // const { usd_ski_rate, user, state } = props;
  // const setting = useApiConf();
  // console.log('StatsCard setting', setting);
  const { user, state } = props;
  // let items = user.withdrawls > 0 ? user.withdrawls : [];
  let items = user.withdrawls;
  // console.log('TransactionCards items', items.length)
  // return state.success ? (
  // return userSuccess(state.user) && items.length > 0 ? (
  return userSuccess(state.user) && items.length > 0 ? (
    <Box maxW="3xl" mx={'auto'} pt={1} pb={16} px={{ base: 2, sm: 12, md: 17 }}>
      <chakra.h1
        textAlign={'center'}
        fontSize={{ base: '1.8em', md: '1.8em' }}
        py={10}
        fontWeight={'bold'}>
        {/* List of withdrawals */}
        Conversioni effettuate
      </chakra.h1>
      
      <SimpleGrid columns={{ base: 1, md: 1 }} spacing={{ base: 5, lg: 8 }}>
      {
        items.length > 0 ?
        <>
        {items.map((item: any, index: any) => {
          return <StatsCard key={index} date={item.checkDate} amount={item.gamepoint} ip={item.requestip} transactionHash={item.txid} success={item.success} price={item.price} env={item.enviroment} text={''} point={item.point} />
        })}
        </>
        :
            <>
              {/* <StatsCard key={1} date={''} amount={''} ip={''} transactionHash={'item.txid'} success={false} price={'36'} text={'No past withdrawals found'} env={'main'} point='' /> */}
              <StatsCard key={1} date={''} amount={''} ip={''} transactionHash={'item.txid'} success={false} price={'36'} text={'Non ci sono conversioni'} env={'main'} point='' />
        {/* <StatsCard key={1} date={'data'} amount={'10'} ip={'127.0.0.1'} transactionHash={'item.txid'} success={true} price={'36'}  text={''} /> */}
        {/* <StatsCard key={1} date={'data'} amount={'10'} ip={'127.0.0.1'} transactionHash={'item.txid'} success={true} price={'36'} text={''} />
        <StatsCard key={1} date={'data'} amount={'10'} ip={'127.0.0.1'} transactionHash={'item.txid'} success={true} price={'36'} text={''} />
        <StatsCard key={1} date={'data'} amount={'10'} ip={'127.0.0.1'} transactionHash={'item.txid'} success={false} price={'36'} text={''} />
        <StatsCard key={1} date={'data'} amount={'10'} ip={'127.0.0.1'} transactionHash={'item.txid'} success={true} price={'36'} text={''} />
        <StatsCard key={1} date={'data'} amount={'10'} ip={'127.0.0.1'} transactionHash={'item.txid'} success={true} price={'36'} text={''} />
        <StatsCard key={1} date={'data'} amount={'10'} ip={'127.0.0.1'} transactionHash={'item.txid'} success={true} price={'36'} text={''} />
        <StatsCard key={1} date={'data'} amount={'10'} ip={'127.0.0.1'} transactionHash={'item.txid'} success={true} price={'36'} text={''} /> */}
        </>
        }
        {/*  */}
      </SimpleGrid>
    </Box>
  ) : (
    <></>
  );
}