import {
  Box,
  // chakra,
  Flex,
  // SimpleGrid,
  // Stat,
  useColorModeValue,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';

interface LoginProps {
    label: string;
    size: string;
  }
export default function Loading(props: LoginProps) {
  // console.log('withdrawls', state.withdrawls);
  const {label, size} = props;
  // const bgGradient = '';
  // const hoverBgGradient = '';
  const from = 'gray.800';
  const to = 'gray.200';
  const color = useColorModeValue(from, to);
  const colorInv = useColorModeValue(to, from);
  return (
    // <Box maxW="3xl" mx={'auto'} pt={1} pb={0} px={{ base: 2, sm: 12, md: 17 }}>
      /* <chakra.h1
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}>
        
      </chakra.h1> */
    //   <SimpleGrid columns={{ base: 1, md: 1 }} spacing={{ base: 5, lg: 8 }}>
        // <Stat
        //   px={{ base: 4, md: 8 }}
        //   py={'5'}
        // //   shadow={'xl'}
        //   border={'0px solid'}
        //   borderColor={colorInv}
        //   rounded={'lg'}
        //   opacity={10}
        // //   bgGradient={bgGradient}
        // //   _hover={{
        // //     bgGradient: hoverBgGradient,
        // //   }}
        // //   color={color}
        //   >
          <Flex justifyContent={'center'}>
            {/* <Box pl={{ base: 2, md: 4 }}>
              <StatLabel fontSize={'xs'} fontWeight={'medium'} isTruncated>
                
              </StatLabel>
              <StatNumber fontSize={'3xl'} fontWeight={'medium'}>
                Loading
              </StatNumber>
            </Box> */}
            <Box
              my={'auto'}
              color={color}
              alignContent={'center'}
              size="120px">
               <CircularProgress isIndeterminate
                // thickness="5px"
                // speed="3s"
                // emptyColor="gray.200"
                trackColor="yellow.500"
                color={colorInv}
                size={size === '' ? 'xs' : size}
              >
                <CircularProgressLabel fontSize={size === '' ? '2xl' : 'xs'}>{label}</CircularProgressLabel>
              </CircularProgress>
            </Box>
          </Flex>
        // </Stat>
    //   </SimpleGrid>
    // </Box>
  );
}