// export const CONTRACT_ADDRESS = '0x887df67fcDb397f1e28d7579bC2b13C519d98cFA'
// export const DEFAULT_NETWORK_ID = 1337;

import { storageKey } from "@chakra-ui/react";

// export const DEFAULT_NETWORK_NAME = "localhost network";
export const CONTRACT_ADDRESS = '0xB64beA712d4Dc3fcb5FDbE3AF5DB4048232FdD26'
export const DEFAULT_NETWORK_ID = '97';
export const DEFAULT_NETWORK_NAME = "Rinkeby test network";
export const TOKEN_ADDRESS = '0x701E9FCCF9f2AeBB0cBA07c969690681171FFeb2'
export const CONTRACT_ABI = []
export const SKIPOINT_RATE = 0.1;
export const USD_SKIPOINT_RATE = 0.034;
export const DEFAULT_ENS = 'SKI';
export const EXPLORER = 'https://testnet.bscscan.com/tx/'

export const TITLE = 'ABTG game area'
export const DATE = () => {
    return new Date().getFullYear()
}
export const COPY = '© ' + DATE() + ' Alfio Bardolla training group s.p.a.'
export const FISCAL = 'Cap. Soc. 2.504.175,00 € - P.Iva 08009280960'
export const USD_SKI_RATE_API = 'https://api.coingecko.com/api/v3/simple/price?ids=skillchain&vs_currencies=usd'


// export const conf = {
//     dex_name: 'token.alfiobardolla.com',
//     dex_url: 'https://token.alfiobardolla.com',
//     coinmarketcap_key: '%env(COINMARKETCAP_KEY)%',
//     bsc_explorer: 'https://testnet.bscscan.com/tx/',
//     middleware_api: 'https://middleware-dev.alfiobardolla.com',
//     default_network_id: 97,
//     default_network_name: 'Rinkeby test network',
//     token_address: '0x701E9FCCF9f2AeBB0cBA07c969690681171FFeb2',
//     skipoint_rate: 0.1,
//     default_ens: 'SKI',
//     default_stable: 'USD',
//     title: 'ABTG game area',
//     copy: 'ABTG - Alfio Bardolla training group s.p.a.',
//     usd_ski_rate_api: 'https://api.coingecko.com/api/v3/simple/price?ids=skillchain&vs_currencies=usd',
//     min_withdraw: 25,
//     // apiconf: await _conf()
// }

export const _settings = async () => {
    const apiMiddlewareUrl = 'https://admintoken.alfiobardolla.com';
    // const apiMiddlewareUrl = 'https://abtg.herokuapp.com';
    return fetch(`${apiMiddlewareUrl}/it/API/apiSettings`, {
    method: 'GET'
  }).then(res => res.json())
}

export async function _conf() {
    // const apiMiddlewareUrl = 'https://token.alfiobardolla.com';
    // const settings = fetch(`${apiMiddlewareUrl}/it/API/apiSettings`, { headers })
    // .then(res => res.json())
    // localStorage.settings = null;

    // window.localStorage.removeItem("settings");
    const settings = await _settings()
    localStorage.settings = JSON.stringify(settings);

    // const settings = () => {
    //       return fetch(`${apiMiddlewareUrl}/it/API/apiSettings`, {
    //       method: 'GET'
    //     }).then(res => res.json())
    //   }
    // console.log('settings', settings);
    return settings;
}

export const getConf = (name) => {
    // console.log('getConf', localStorage.settings)
    const conf = JSON.parse(localStorage.settings);
    // console.log('getConf', conf)
    return conf[name];
}
// console.log(await settings());
