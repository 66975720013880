// import { useState } from "react";
import { Button, Box, Text, Link } from "@chakra-ui/react";
// import { useEthers, useEtherBalance, useTokenBalance, shortenIfAddress } from "@usedapp/core";
import { useEthers, useTokenBalance, shortenIfAddress } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import { getConf, TOKEN_ADDRESS } from '../config'
import * as wUtils from '../utils/WalletUtils'

// import Identicon from "./Identicon";

// type Props = {
//   handleOpenModal: any;
// };

type ButtonProps = {
  // updateAccounts: any;
  updateWallet: any;
  user: any;
};

// const SKI = '0x701E9FCCF9f2AeBB0cBA07c969690681171FFeb2'

export function GetAccount() {
  const { activateBrowserWallet, account, chainId } = useEthers();
  // const etherBalance = useEtherBalance(account);
  return {activateBrowserWallet, account, chainId};
}
// export function ACCOUNT(props: Props) {
//   return props.accounts[0];
// };

export default function ConnectButton(props: ButtonProps) {
  // const { user, updateWallet } = props;
  const { user } = props;
  // const handleAccountsChanged = (accounts: string[]): void {
  //   if (__DEV__) {
  //     console.log("Handling 'accountsChanged' event with payload", accounts)
  //   }
  //   this.emitUpdate({ account: accounts.length === 0 ? null : accounts[0] })
  // }
  // const [walletValue, setWalletValue] = useState('');
// export default function ConnectButton(props: Props) {
// export default function ConnectButton({updateAccounts, accounts}: Props) {
// export default function ConnectButton({ account }: Props) {
// function ConnectButton({ updateAccounts }: Props) {
// const ConnectButton: React.FC<{updateAccounts: any; accounts: any}> = (props) => {
// [ConnectButton, account] = () => {
// function ConnectButton() {
  // const { activateBrowserWallet, account, chainId } = GetAccount();
  // const { activateBrowserWallet, account, chainId } = useEthers();
  const { activateBrowserWallet, account } = useEthers();
  localStorage.account = account;
  // account != undefined  ? console.log('success account', account) : console.log('fail account', account);
  // const { activateBrowserWallet, account, chainId } = useEthers();
  // console.log('chainId', chainId);
  // const etherBalance = useEtherBalance(account);
  const tokenBalance = useTokenBalance(getConf('token_address'), account)
  // const [accounts, setAccounts] = React.useState("")
  // console.log('updateAccounts', updateAccounts);
  // const [user, setUser] = useState({account: account});
  // console.log('user', user);
  // if (account) {
  //   // const tokenBalance = useTokenBalance(SKI, account)
  // // props.accounts[0] = account;
  //   // props.account = account;
  //   // props.updateAccounts(account);
  //   // accounts[0] = account;
  // setUser({account});
  // }

  // const shortAddress = (user: any) => {
  //   try {
  //     return shortenIfAddress(user != undefined ? user.wallet : '')
  //     } catch (error) {
  //      return user != undefined ? user.wallet : ''
  //     }
  // }

  function handleConnectWallet() {
    console.log('handleConnectWallet');
    activateBrowserWallet();
    // accounts[0] = account;
    // updateAccounts(account);
  }
  // console.log('Props.accounts', props.accounts);

  // function updateAcc() {
  //   updateAccounts(account);
  // }


  const connectButton = account ? (
  // return account ? (
    <Box
      display="flex"
      alignItems="center"
      background="gray.700"
      borderRadius="xl"
      py="0"
      mt={{ base: '-35px', md: '-40px' }}
      h={{base: '30px', md: '38px'}}
    >
      {/* <Text color={'black'}>{getConf('default_network_name')}</Text>  */}
      {/* <Box px={{ base: '2', md: '5' }}> */}
        {/* <Link href={getConf('bsc_explorer') + 'address/' + account + '#tokentxns'} target='_blank'>
        <Text color="white" fontSize={{ base: '0.5em', md: 'xs' }}> */}
          {/* {getConf('default_short_network_name')}{' '} */}
          {/* {tokenBalance ? parseFloat(formatEther(tokenBalance)).toFixed(3) : '0' } {getConf('default_ens')} */}
          {/* {tokenBalance && parseFloat(formatEther(tokenBalance)).toFixed(3)} {getConf('default_ens')} */}
        {/* </Text>
        </Link>
      </Box> */}
      <Button
        // onClick={handleOpenModal}
        bg="gray.800"
        border="1px solid transparent"
        _hover={{
          border: "1px",
          borderStyle: "solid",
          borderColor: "blue.400",
          backgroundColor: "gray.700",
        }}
        borderRadius="xl"
        m="0px"
        px={3}
        h={{ base: '30px', md: '38px' }}
      >
        <Text color="white" fontSize={{ base: '0.6em', md: 'xs' }} fontWeight="medium" mr="0">
          {/* {account &&
            `${account.slice(0, 6)}...${account.slice(
              account.length - 4,
              account.length
            )}`} */}
            { shortenIfAddress(account) }

            {/* { shortenIfAddress(account) ? console.log('success account', account) : console.log('success account', account) } */}
            {/* { async () => {
          await updateWallet(walletValue)
          // deactivate();
          // onClose()
        } */}
        </Text>
        {/* <Identicon /> */}
      </Button>
    </Box>
  ) : (
    <Button
      onClick={handleConnectWallet}
      // onChange={props.updateAccounts('ciccio')}
      bg="blue.800"
      color="blue.300"
      fontSize="xs"
      fontWeight="medium"
      borderRadius="xl"
      border="1px solid transparent"
      _hover={{
        borderColor: "blue.700",
        color: "blue.400",
      }}
      _active={{
        backgroundColor: "blue.800",
        borderColor: "blue.700",
        }}
        py="0"
        mt={{ base: '-35px', md: '-40px' }}
        h={{ base: '30px', md: '38px' }}
    >
        <Text color="white" fontSize={{ base: '0.6em', md: 'xs' }} fontWeight="medium" mr="0">
          {/* {wUtils.hasWallet(user) ? wUtils.shortAddress(user) : 'Connect metamask'} */}
          {wUtils.hasWallet(user) ? wUtils.shortAddress(user) : 'Connetti metamask'}
      </Text>
    </Button>
  );

  return connectButton
  // return { connectButton, account }
  // return [button, account];
}
// console.log('ConnectButton account', ConnectButton().account);



// export {
//   ConnectButton,
//   // GetAccount
// }