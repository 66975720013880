import React, { Component } from "react";
// import { Route } from "react-router-dom";
import Header from './Header'
// import Footer from "./Footer";
import { 
  ChakraProvider,
  Slide,
  Flex,
  Box,
  Text
 } from '@chakra-ui/react'
import * as AbtgAPI from '../utils/AbtgAPI'
import { myTheme } from "../theme";
import Body from "./Body";
import Footer from "./Footer";
import Login from "./Login";
import { _conf } from "../config";

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link as RouteLink,
//   useParams
// } from "react-router-dom";

import { getToken, loginWithToken, loginWithEmailPassword } from '../utils/SessionToken'
import { Notification, toastUser } from "../utils/Notification";
import { hasWallet } from "../utils/WalletUtils";
// import { walletAddress, isUserValid, getUser } from "../utils/WalletUtils";
import * as wUtils from '../utils/WalletUtils'
// import { GetAccount } from "./ConnectButton";
// import { useEthers } from "@usedapp/core";

// import { GetAccount } from "./ConnectButton";
// import { useEthers } from "@usedapp/core";

// import { extendTheme } from "@chakra-ui/react"
// import { mode } from '@chakra-ui/theme-tools'

// 2. Call `extendTheme` and pass your custom values
// const theme = extendTheme({
//   styles: {
//     global: (props) => ({
//       body: {
//         fontFamily: 'body',
//         color: mode('gray.800', 'whiteAlpha.900')(props),
//         bg: mode('gray.200', 'gray.800')(props),
//         lineHeight: 'base',
//       },
//     }),
//   },
//   colors: {
//     brand: {
//       100: "#f7fafc",
//       // ...
//       900: "#1a202c",
//     },
//   },
//   config: {
//     useSystemColorMode: true,
//     initialColorMode: "dark"
//   }
// })
// const config = {
//   initialColorMode: "dark",
//   useSystemColorMode: false,
// }

// const theme = extendTheme({ config })

// const User = () => {
//   const { userName } = useParams();
//   return (
//       <div>Username: { userName }</div>
//   );
// }

// const { account } = GetAccount();
// console.log('getToken', getToken());

// const Notification = (title, description, status) => {
//   const toast = createStandaloneToast({ theme: myTheme });
//   toast({
//       title: title,
//       description: description,
//       status: status,
//       duration: 3000,
//       position: 'bottom-right',
//       isClosable: true,
//     });

//   return <></>;
// };
// const account = '';
// const { account, chainId } = () => useEthers();
// console.log('Main account', account);

class Main extends Component {
  componentWillUnmount() {
    clearInterval(this.timerSkiUsdRate);
  }
  async componentDidMount() {
    await getToken();
    // console.log('Main conf', conf);
    // await this.getSettings();
    //LOAD SETTINGS TO LOCAL STORAGE
    // window.localStorage.clear();
    await _conf();
    // console.log('Main settings', settings);
    // console.log('Main localStorage.settings', getConf('dex_url'));
    // this.setState({
    //   conf: settings
    // })
    await this.loginWithToken();
    this.updateSkiUsdRate()
    this.timerSkiUsdRate = setInterval(
      () => this.updateSkiUsdRate(),
      // 25000
      100000
    );
    const { ethereum } = window;
    if (!ethereum) {
      // alert('Please install metamask!');
      this.setState({
        hasMetamask: false
      })
    } else {
      // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      // this.updateWallet(accounts[0]);
      // console.log('Main account', accounts[0]);
      const accounts = [];
      window.ethereum.on('accountsChanged', async (accounts) => {
        // console.log('accountsChanged accounts', accounts);
        this.updateWallet(accounts.length > 0 ? accounts[0] : '');
      });
      // console.log('ethereum.isConnected()', window.ethereum.isConnected());
      if (window.ethereum.isConnected()) {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        this.updateWallet(accounts[0]);
        // console.log('Main account', accounts[0]);
      }
    }
    
    // const { account, chainId } = () => useEthers();
    // console.log('Main account', account);
    // Web3Ethers.
    // const result = await loginWithToken().then((result) => {
    //   console.log('loginWithToken result', result);
    //   this.setState({
    //     user: result.user,
    //     success: result.user.success,
    //     withdrawls: result.user.withdrawls
    //   })
    // });
    // console.log('result', result);
    // SessionToken().then((result) => {
    //   console.log('result', result);
    //   // this.setState({
    //   //   user: user,
    //   //   success: user.success,
    //   //   withdrawls: user.withdrawls
    //   // })
    // });
    // try {
    //   // getToken = async () => {
    //   const { token, user } = SessionToken().then((result) => {
    //     console.log('result', result);
    //     this.setState({
    //       user: user,
    //       success: user.success,
    //       withdrawls: user.withdrawls
    //     })
    //   });
    //   this.setState({
    //     user: user,
    //     success: user.success,
    //     withdrawls: user.withdrawls
    //   })
    // } catch (error) {
    //   console.log('Error on componentDidMount '+error)
    //   this.setState({
    //     // user: user,
    //     success: false,
    //     // withdrawls: []
    //   })
    // }
    // AbtgAPI.loginWithToken(token).then((user) => {
    //   if (user.success) {
    //     // user.lastbalance = 10;
    //     this.setState({
    //       user: user,
    //       success: user.success,
    //       withdrawls: user.withdrawls
    //     })
    //     // Notification('Complete.', 'Data has been loaded.', 'success');
    //   }
    //   console.log(user);
    //   // console.log('withdrawls', user.withdrawls);
    // })
    // AbtgAPI.skiUsdRate().then((usd_ski_rate) => {
    //   this.setState({
    //     usd_ski_rate: usd_ski_rate.skillchain.usd
    //   })
    //   // console.log(usd_ski_rate.skillchain.usd);
    // })

    // this.timerCanWithdraw = setInterval(
    //   () => {
    //     this.setState({
    //       canWithdraw: wUtils.canWithdraw(this.state.user, this.state.usd_ski_rate)
    //     })
    //     console.log('timerCanWithdraw state.canWithdraw', this.state.canWithdraw);
    //   },
    //   5000
    // );
    // ['updateCiccio']
    //         .forEach((fn) => this[fn] = this[fn].bind(this));
  }
  
  updateSkiUsdRate() {
    // console.log('updateSkiUsdRate');
    try {
      // AbtgAPI.skiUsdRate().then((usd_ski_rate) => {
      //   this.setState({
      //     usd_ski_rate: usd_ski_rate.skillchain.usd
      //   })
      //   // console.log('updateSkiUsdRate', usd_ski_rate.skillchain.usd);
      // })
      AbtgAPI.skiUsdRate().then((usd_ski_rate) => {
        // Coingeko
        // this.setState({
        //   usd_ski_rate: usd_ski_rate.price
        // })
        // Pancakeswap
        this.setState({
          usd_ski_rate: Number(usd_ski_rate.guaranteedPrice)
        })
        // console.log('updateSkiUsdRate', usd_ski_rate.skillchain.usd);
      })
    } catch (error) {
      // console.log('updateSkiUsdRate problem connecting to Coingeko');
      Notification('Info.', 'Errore interno; non è stato possibile aggiornare il valore dei token SKI sulla base di Pancakeswap', 'info');
      return;
      // Notification('Info.', 'Web socket delay', 'info');
    }
  }
  
  updateUser(token) {
    try {
      AbtgAPI.loginWithToken(token).then((user) => {
        this.setState({
          user: wUtils.getUser(user)
        })
        console.log(wUtils.getUser(user));
      })
    } catch (error) {
      // Notification('Info.', 'Unable to update user', 'info');
      Notification('Info.', 'Errore interno; non è stato possibile leggere i dati del tuo account', 'info');
    }
  }

  // getSettings = async () => {
  //   const {conf} = await settings();
  //   this.setState({
  //     conf: conf
  //   })
  //   // console.log(conf);
  // }

  loginWithToken = async () => {
    try {
      // console.log('loginWithToken user');
      this.setState({
        loggedFail: false,
        success: false,
      })
      // const { token, user } = await loginWithToken();
      const { user } = await loginWithToken();
      // console.log('loginWithToken user', wUtils.getUser(user));
      this.setState({
        user: wUtils.getUser(user),
        success: wUtils.userSuccess(user),
        withdrawls: wUtils.isUserValid(user) ? user.withdrawls : [],
        loggedFail: !wUtils.userSuccess(user),
      })
    // user.success ? Notification('Complete.', user.message[0], 'success') : Notification('Error.', user.error[0], 'error');
    } catch (error) {
      // this.setState({
      //   loggedFail: false,
      //   success: false,
      // })
      this.setState({
        user: wUtils.getUser(undefined),
        loggedFail: true,
        success: false,
        onSubmit: false,
      })
      // console.log('loginWithToken error', error)
      // Notification('Error.', 'Unable to login with provided token', 'error');
      // Notification('Error.', error, 'error');
      // Notification('Error.', 'Unexepcted error. Unable to login with stored auth token.', 'error', 3000);
      // Notification('Info.', 'Try login with e-mail and password', 'info');
      Notification('Info.', 'Riprova inserendo email e password', 'info');
      Notification('Error.', 'Errore di sitema. Impossibile autenticarsi con il authToken attivo.', 'error', 3000);
    }
  }

  loginWithCredential = async (email, password, tel) => {
    try {
      // console.log('loginWithCredential email', email);
      // console.log('loginWithCredential password', password);
      this.setState({
        loggedFail: false,
        success: false,
        onSubmit: true,
        email: email,
        password: password,
        tel: tel
      })
      // const { token, user } = await loginWithEmailPassword(email, password);
      const { user } = await loginWithEmailPassword(email, password, tel);
      // console.log('loginWithCredential tel', this.state.tel);
      // console.log('loginWithCredential user', wUtils.getUser(user));
      this.setState({
        user: wUtils.getUser(user),
        success: wUtils.userSuccess(user),
        withdrawls: wUtils.isUserValid(user) ? user.withdrawls : [],
        loggedFail: !wUtils.userSuccess(user),
        onSubmit: false,
      })
      // user.success ? Notification('Complete.', user.message[0], 'success') : Notification('Error.', user.error[0], 'error');
    } catch (error) {
      this.setState({
        user: wUtils.getUser(undefined),
        loggedFail: true,
        success: false,
        onSubmit: false,
        email: email,
        password: password,
        tel: tel
      })
      // Notification('Error.', 'Unable to login with email and password', 'error');
      // Notification('Info.', 'Please reload page', 'info');
      // Notification('Error.', 'Unexepcted error. Unable to login with email and password.', 'error', 3000);
      Notification('Info.', 'Riprova più tardi', 'info');
      Notification('Error.', 'Errore inaspettato nel processo di login con email e password.', 'error', 3000);
    }
  }


  updateWallet = async (wallet) => {
    // const { ethereum } = window;
    // if (ethereum) {
    //   const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    //   wallet = accounts[0];
    // }
    try {
      if (!wUtils.userSuccess(this.state.user)) {
        // console.log('updateWallet user not logged');
        this.setState({
          onSubmit: false,
        })
        return;
      }
      const userWallet = wUtils.walletAddress(this.state.user);
      if (userWallet === wallet) {
        // console.log('updateWallet not needed');
        // Notification('Info.', 'The wallet is identical to the already setted wallet', 'info');
        this.setState({
          onSubmit: false,
        })
        return;
      }
      if (!wUtils.checkWallet(wallet)) {
        // Notification('Error.', 'Invalid wallet.', 'error');
        Notification('Error.', 'Wallet non valido.', 'error');
        this.setState({
          onSubmit: false,
        })
        return;
      }
      this.setState({
        onSubmit: true,
      })
      // const { token, user } = await loginWithEmailPassword(email, password);
      // console.log('updateWallet wallet', wallet);
      const { token } = await getToken();
      const user = await AbtgAPI.updateWallet(token, wallet)
      // console.log('updateWallet user', wUtils.getUser(user));
      // wUtils.userSuccess(user) ? Notification(user.message[0], '', 'success') : Notification('Error.', user.error[0], 'error');
      // if (user.success) {
        this.setState({
          user: wUtils.getUser(user),
          // success: user != undefined ? user.success : false,
          // withdrawls: user != undefined ? user.withdrawls : [],
          // loggedFail: user != undefined ? !user.success : !user.success,
          onSubmit: false,
        })
        toastUser(user);
      // }
      // this.setState({
      //   onSubmit: false,
      // })
      // user.success ? Notification('Complete.', user.message[0], 'success') : Notification('Error.', user.error[0], 'error');
    } catch (error) {
      this.setState({
        onSubmit: false,
      })
      // Notification('Error.', error, 'error');
      // Notification('Info', 'Unable to update user wallet', 'info');
      Notification('Info', 'Non è possibile aggiornare il wallet', 'info');
    }
  }

  withdraw = async () => {
    try {
      // this.setState({
      //   inWithdraw: true
      // })
      // console.log('inWithdraw', this.state.inWithdraw);
      if (!hasWallet(this.state.user)) {
        Notification('Error.', 'Invalid wallet.', 'error');
        this.setState({
          inWithdraw: false
        })
        return;
      }
      // if ()
      // console.log('withdraw');
      if (!this.state.inWithdraw) {
        this.setState({
          inWithdraw: true
        })
        const { token } =  await getToken();
        AbtgAPI.withdrawAPI(token).then((user) => {
          // console.log(user);
          // wUtils.userSuccess(user) ? Notification('Complete.', user.message[0], 'success') : Notification('Error.', user.error[0], 'error');
          toastUser(user);
          user.success = true;
          this.setState({
            user: wUtils.getUser(user) ? user : this.state.user,
            // withdrawn: false,
            inWithdraw: false
          });
          // console.log(this.state.user);
        })
      }
    } catch (error) {
      this.setState({
        inWithdraw: false,
      })
      // Notification('Info', 'Unable to withdraw', 'info');
      Notification('Info', 'Errore nel convertire i punti in SKI.', 'info');
    }
  }

  // updateAccounts = (accounts) => {
  //   console.log('updateAccounts', accounts)
  //   const account = accounts;
  //   console.log('updateAccounts', account)
  //   // this.setState({
  //   //   account: accounts
  //   // })
  //   // console.log('this.state.accounts[0]', this.state.accounts[0]);
  // }

  // changeNavigation = () => {
  //   this.setState({
  //     screen: this.state.screen === 'list' ? 'create' : 'list'
  //   })
  // }

  // removeAttraction = (attraction) => {
  //   this.setState((state) => ({
  //     attractions: state.attractions.filter((attr) => attr.id !== attraction.id)
  //   }))
  //   AttractionsAPI.remove(attraction).then((attractions) => {
  //     console.log(attractions);
  //   })
  // }

  // createPlace = (place) => {
  //   // console.log('place', place)
  //   AttractionsAPI.create(place).then((place) => {
  //     // this.setState({
  //     //     attractions: [...this.state.attractions, place]
  //     //   })
  //     this.setState(state => ({
  //         attractions: state.attractions.concat(place)
  //       }))
  //     console.log(this.state.attractions)
  //   })
  // }

  state = {
    // attractions: [],
    // title: 'ABTG game area',
    // subtitle: 'I miei posti preferiti',
    hasMetamask: true,
    loggedFail: false,
    success: false,
    user: undefined,
    conf: {},
    // accumulatedPoint: 1000000000000000000n,
    // availibility: 1000000000000000000n,
    usd_ski_rate: 0,
    // withdrawls: [],
    withdraw: this.withdraw,
    // loginWithCredential: this.loginWithCredential,
    withdrawn: false,
    withdrawnMessage: '',
    inWithdraw: false,
    onSubmit: false,
    // screen: 'list', //list, create
  }

  render() {
    // console.log('render state', this.state);
    // console.log('Main this.state.loggedFail', this.state.loggedFail);
    return (
      // <Router>
      // <Route exact path='/user/:userName' component={User} />
      // <Route>
    <ChakraProvider theme={myTheme}>
    
      <Header user={this.state.user} hasMetamask={this.state.hasMetamask} updateWallet={this.updateWallet} state={this.state} />
      {/* <Flex
        pos="fixed"
        w='100%'
        h="100%"
        // border={'1px'}
       > */}
      {/* {
        this.state.success ? 
        <> */}
        {
          !this.state.loggedFail ?
          <>
              <Body usd_ski_rate={this.state.usd_ski_rate} user={this.state.user} state={this.state} updateWallet={this.updateWallet} />
              <Text mb={'150px'} color='abtg.gray'>.</Text>
          </>
          :
            // <div className="AppContent">
              <Login loginWithCredential={this.loginWithCredential} state={this.state} />
          // </div>
        }
        {/* </>
        :
        <Text>Hello</Text>
      } */}
      {/* <Header user={this.state.user} updateAccounts={this.updateAccounts} accounts={this.state.accounts} account={this.state.account} /> */}
      {/* <Box m={2}>{this.state.accounts.length > 0 ? this.state.accounts[0] : 'Undefined wallet'}</Box> */}
      {/* <Body usd_ski_rate={this.state.usd_ski_rate} user={this.state.user} state={this.state} />
      <Footer /> */}
      {/* <Box m={2}>Account: {account}</Box> */}
      {/* </Flex> */}
      {/* <Flex
          as="footer"
          bg={"blue.200"}
          layerStyle="footer"
          display={true ? "flex" : "none"}
        >
          <Box layerStyle="footer.body">Footer</Box>
        </Flex> */}
        <Slide direction="bottom" >
            <Footer />
        </Slide>
    </ChakraProvider>
            //         </Route>
            // </Router>
    )
  }
}

export default Main;