
// import { useSearchParams } from "react-router-dom";
import { toastUser } from "./Notification";
import * as AbtgAPI from './AbtgAPI'
import { FaWindows } from "react-icons/fa";
// import * as wUtils from '../utils/WalletUtils'

// export async function SessionToken() {
//     // const name = queryParams.get('name');
//     // const type = queryParams.get('type');
//     // console.log(id, name, type); // 55 test null

//     let token = localStorage.token;
//     if (!token) {
//         const queryParams = new URLSearchParams(window.location.search);
//         token = localStorage.token = queryParams.get('token');
//     //     token = () => {
//             // Math.random().toString(36).substr(-8)
//             // const [searchParams, setSearchParams] = useSearchParams();
//             // token = searchParams.get("token")
//             // console.log('token', token)
//     }
//     console.log('token', token);
//     const user = null;
//     if (token) {
//         AbtgAPI.loginWithToken(token).then((user) => {
//             // console.log(user);
//         //   if (user.success) {
//         //     // user.lastbalance = 10;
//         //     this.setState({
//         //       user: user,
//         //       success: user.success,
//         //       withdrawls: user.withdrawls
//         //     })
//         //     user.success ? Notification('Complete.', user.message[0], 'success') : Notification('Error.', user.error[0], 'error');
//         //     // Notification('Complete.', 'Data has been loaded.', 'success');
//         //   }
//         //   console.log(user);
//           // console.log('withdrawls', user.withdrawls);
//             // return { 'token': token, 'user': user };
//         })
//     }
//     return { 'token': token, 'user': user };
// }

export async function getToken() {
  let redirect = false;
  // let token = window.location.pathname.substring(1)
  // console.log('getToken token', token);
  // token=token.substring(1)
  // console.log('getToken token', token);
  // if (token) redirect = true;
    const queryParams = new URLSearchParams(window.location.search);
    let token = queryParams.get('token');
    if (token) redirect = true;
    if (!token) {
        token = localStorage.token;
    }
    localStorage.token = token;
    // console.log('localStorage token', token);

  // console.log('getToken window.location.protocol+window.location.host', window.location.host);
    if (redirect) {
      window.location.href = window.location.protocol+'//'+window.location.host;
      // window.location.replace(window.location.host);
    };
    return { 'token': token };
}

export const loginWithToken = async () => {
    try {
        const { token } = await getToken()
        // const queryParams = new URLSearchParams(window.location.search);
        // let token = queryParams.get('token');
        // if (!token) {
        //     token = localStorage.token;
        //     // console.log('localStorage token', token);
        // }
        // localStorage.token = token;
        // console.log('token', token);
        // const user = null;
        if (token !== 'undefined') {
            // const user = await AbtgAPI.loginWithToken(token).then((user) => {
            //     console.log(user);
            //     user.success ? Notification('Complete.', user.message[0], 'success') : Notification('Error.', user.error[0], 'error');
            // });
            const user = await AbtgAPI.loginWithToken(token)
            toastUser(user);
            // user.success ? Notification(user.message[0], '', 'success') : Notification('Error.', user.error[0], 'error');
            // console.log('token dopo await', token);
            // console.log('user dopo await', user);
            return { 'token': token, 'user': user };
            // AbtgAPI.loginWithToken(token).then((user) => {
            //     console.log(user);
            // //   if (user.success) {
            // //     // user.lastbalance = 10;
            // //     this.setState({
            // //       user: user,
            // //       success: user.success,
            // //       withdrawls: user.withdrawls
            // //     })
            // //     user.success ? Notification('Complete.', user.message[0], 'success') : Notification('Error.', user.error[0], 'error');
            // //     // Notification('Complete.', 'Data has been loaded.', 'success');
            // //   }
            //   console.log('inside loginWithToken user', user);
            //   return { 'token': token, 'user': user };
            //   // console.log('withdrawls', user.withdrawls);
            // })
            // console.log('outside loginWithToken user', user);
        } else {
            return { 'token': token, 'user': undefined };
        }
    }
    catch (e) {
      console.log('We have the error', e);
    }
  }

  export const loginWithEmailPassword = async (email, password, tel) => {
      try {
        const user = await AbtgAPI.loginWithEmailPassword(email, password, tel)
        console.log('loginWithEmailPassword user', user);
        toastUser(user);
        // user.success ? Notification(user.message[0], '', 'success') : Notification('Error.', user.error[0], 'error');
        const token = localStorage.token = user.token ? user.token : 'undefined';
        return { 'token': token, 'user': user };
      }
      catch (e) {
        console.log('We have the error', e);
      }
    }

// export function formatEther(wei: BigNumberish): string {
//     return formatUnits(wei, 18);
// }